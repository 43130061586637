<template>
    <div class="wrap f-c-c-c">
        <div>©2016-2019 海南搜了科技股份有限公司版权所有</div>
        <div>琼ICP备18002094号-3</div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style lang="scss" scoped>
.wrap{
    font-size: 12px;
    color: $color-text-light;
    height: 60px;
    line-height: 1.5;
}
</style>