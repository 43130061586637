<template>
  <div>
    <MainHeader />
    <div id="body" class="f-fs-s">
      <TreeMenu :menus="menus" />
      <div id="main">
        <div id="router-view-box">
          <RouterView />
        </div>
        <MainFooter />
      </div>
    </div>
  </div>
</template>
<script>
import TreeMenu from "@/components/part/TreeMenu.vue";
import MainHeader from "@/components/part/MainHeader.vue";
import MainFooter from "@/components/part/MainFooter.vue";
export default {
  components: {
    TreeMenu,
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      menus: [], //递归菜单数据
      currMenuPaths: [], //当前菜单路径，例如：["系统管理","用户"]
    };
  },
  created() {
    this.getMenus();
  },
  mounted() {},
  methods: {
    getMenus() {
      // this.$api.view.getMenus().then((res) => {
      //   console.log(data);
      // let { code, message, data } = res;
      // if (code == 1) {
      this.menus = [
        {
          id: "1",
          name: this.$t('views.Manage.792054-0'),
          icon: "el-icon-message-solid",
          children: [
            {
              id: "11",
              name: this.$t('views.Manage.792054-1'),
              url: "/manage/shop/info",
            },
          ],
        },
        {
          id: "2",
          name: this.$t('views.Manage.792054-2'),
          icon: "el-icon-message-solid",
          children: [
            {
              id: "21",
              name: this.$t('views.Manage.792054-3'),
              url: "/manage/goods/publish",
            },
            {
              id: "12",
              name: this.$t('views.Manage.792054-4'),
              url: "/manage/goods/list",
            },
          ],
        },
        {
          id: "3",
          name: this.$t('views.Manage.792054-5'),
          icon: "el-icon-message-solid",
          children: [
            {
              id: "31",
              name: this.$t('views.Manage.792054-6'),
              url: "/manage/order/arrList",
            },
            {
              id: "32",
              name: this.$t('views.Manage.792054-7'),
              url: "/manage/order/refund",
            },
            {
              id: "33",
              name: this.$t('views.Manage.792054-8'),
              url: "/manage/order/RestockLists",
            },
          ],
        },
        {
          id: "4",
          name: this.$t('views.Manage.792054-9'),
          icon: "el-icon-message-solid",
          children: [
            {
              id: "41",
              name: this.$t('views.Manage.792054-10'),
              url: "/manage/finance/report",
            },
            {
              id: "42",
              name: this.$t('views.Manage.792054-11'),
              url: "/manage/finance/withdraw",
            },
          ],
        },
        // {
        //   id: "5",
        //   name: "运营活动管理",
        //   icon: "el-icon-message-solid",
        //   children: [
        //     {
        //       id: "51",
        //       name: "砍价活动",
        //       children: [
        //         {
        //           id: "511",
        //           name: "管理活动商品",
        //           url: "/manage/activity/cutPrice/manageGoods",
        //         },{
        //           id: "512",
        //           name: "创建/编辑活动商品",
        //           url: "/manage/activity/cutPrice/addOrEditGoods",
        //         }
        //       ],
        //     },
        //   ],
        // },
      ];
      //只有这个账号才能查看运营活动管理的相关信息
      // if(this.$store.state.user.userId !== "1659058"){
      //   this.menus.splice(4,1);
      // }
      if (
        this.$store.state.user.userId != "1261964" &&
        this.$store.state.user.userId != "1659058"
      ) {
        this.menus.splice(4, 1);
      }
      //   this.$store.commit("save", { path: "menus", data });
      // } else {
      //   this.$message.error(message);
      // }
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
#body {
  height: calc(100vh - 70px);
}
#main {
  flex-grow: 1;
  overflow: auto;
  padding: 0 15px;
  box-sizing: border-box;
}
#router-view-box {
  min-height: calc(100% - 60px);
}
</style>
