<template>
  <div class="main-header f-sb-c">
    <div class="info f-fs-s">
      <el-image
        class="rotate avatar"
        :src="user.avatar || require('@/assets/img/default/avatar.gif')"
        fit="contain"
      ></el-image>

      <div class="my f-c-fs-c">
        <div class="nickname">{{ user.nickname }}</div>
        <div>
          <el-button @click="loginOut" size="mini" type="primary">{{
            $t("part.MainHeader.912422-0")
          }}</el-button>
        </div>
      </div>
    </div>

    <div>
      <el-button @click="chekLanguage('zh')" size="mini" type="primary"
        >{{ $t('part.MainHeader.680378-0') }}</el-button
      >
      <el-button @click="chekLanguage('mn')" size="mini" type="primary"
      >{{ $t('part.MainHeader.680378-2') }}</el-button
      >
      <el-button @click="chekLanguage('en')" size="mini" type="primary"
        >{{ $t('part.MainHeader.680378-1') }}</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    this.$i18n.locale = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "zh";
  },
  mounted() {},
  methods: {
    loginOut() {
      this.$store.replaceState(Object.assign({}, this.$store.state, {}));
      this.$store.commit("save", {
        path: "currMenuPaths",
        data: [
          this.$t("part.MainHeader.912422-1"),
          this.$t("part.MainHeader.912422-2"),
        ],
      });
      localStorage.clear();
      this.$router.push("/login");
      this.$message.success(this.$t("part.MainHeader.912422-3"));
    },

    chekLanguage(val) {
      localStorage.setItem("lang", val);
      localStorage.removeItem('switchTab')
      this.$i18n.locale = val; 
      location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.main-header {
  height: 70px;
  padding: 0 20px;
  background: $color-bg-main;
}
.info {
  .avatar {
    cursor: pointer;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin-right: 20px;
  }

  .nickname {
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}

.title {
  color: $color-theme;
  margin-left: 30px;
}
</style>
